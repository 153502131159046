import React, { useCallback, useRef, useEffect, useState } from "react"
import Button from "../components/button";
import Layout from "../components/layout";
import { addToCheckout, checkAvailability } from "../redux/actions";
import { getStore } from "../redux/store";
import { CarouselProvider, Slider, Image, Dot, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import styled from 'styled-components';
import Price from '../components/price';
import Size from '../components/size';
import Title from '../components/title';
import Separator from "../components/separator";
import Description from "../components/description";
import { useClientEffect } from "../redux/utils";
import { useIsAdding, useProductAvailable } from "../redux/selectors";
import TitleEffect from "../components/title-effect";
import Crotchet from "../components/crotchet";
import { Link } from "gatsby";
import ColoredSeparator from "../components/colored-separator";
import Path from "../components/path";
import Meta from "../components/meta";
import Macaron from "../components/macaron";
import { useDispatch } from "react-redux";
import { extractPrice } from "../utils/price";

const CarouselContainer = styled.div`
  width: 450px;
  max-width: 100vw;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const DotsContainer = styled.div`
  margin-top: 12px;
  display: flex;
`

const StyledDot = styled(Dot)`
  background-color: transparent;
  box-sizing: border-box;

  :nth-child(1n){
    padding-left: 12px;
    padding-right: 12px;
  }

  :first-child{
    padding-left: 0px;
  }
  :last-child{
    padding-right: 0px
  }
`

const DotImage = styled.img`
  width: 100%;
`;

const MyNextButton = styled(ButtonNext)`
  position: absolute;
  top: 50%;
  right: 0;
  border: none;
  background-color: transparent;
`;

const MyPrevButton = styled(ButtonBack)`
  position: absolute;
  top: 50%;
  left: 0;
  border: none;
  background-color: transparent;
`;

const Carousel = ({ images, name, tags }) => (
  <CarouselProvider
    naturalSlideWidth={350}
    naturalSlideHeight={490}
    totalSlides={images.length}
  >
    <CarouselContainer>
      <Path name={name} />
      <Separator size={8} />
      <ImageContainer>
        <Slider>
          {images.map((img, i) => (
            <Slide index={i} key={i}>
              <Image src={img} />
              <MyNextButton>{">"}</MyNextButton>
              <MyPrevButton>{"<"}</MyPrevButton>
              <ButtonBack />
            </Slide>
          ))}
        </Slider>
        <Macaron tags={tags} />
      </ImageContainer>
      <DotsContainer>
        {images.map((img, i) => (
          <StyledDot key={i} slide={i} style={{ border: 'none' }} images={images.length}>
            <DotImage src={img} />
          </StyledDot>
        ))}
      </DotsContainer>
    </CarouselContainer>
  </CarouselProvider>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Informations = styled.div`
  margin-top: calc(8px + 1rem);
  margin-left: 48px;
  width: 450px;
  max-width: 100vw;
  @media (max-width: 768px) {
    padding:12px;
    margin-left: 0;
    box-sizing: border-box;
  }
`;

const SizesContainer = styled.div`
  display: flex;
  & > * {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const SizeStyled = styled(Size)`
  &:first-child {
    margin-left:0;
  }
`;

const LookContainer = styled.div`
  width: 100%;

`;

const Looks = styled.div`
  width: 100%;
  background-color: #303030;
  position: relative;
  margin-top: 96px;
  height: ${props => props.height}px;
`;

const OutsideContainer = styled.div`
  position: absolute;
  top: -88px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin-right: 14px;
    margin-left: 14px;
    margin-top: 32px;
  }
`;

const Look = styled(Link)`
  width: 250px;
  padding: 18px;
  background-color: #f1ece9;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

const LookImage = styled.img`
  width: 100%;
`;

const LookTitle = styled.div`
  margin-top: 12px;
  font-size: 1.5rem;
  color: black;
`;
const LookPrice = styled.div`
  color: #5F5F5F;
  font-size: 1.2rem;
`;

const LookImageContainer = styled.div`
  position: relative;
`;

function useHeight(elementRef) {
  const [height, setHeight] = useState(null);

  const updateHeight = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { clientHeight } = elementRef.current;
      setHeight(clientHeight);
    }
  }, [elementRef]);

  useEffect(() => {
    updateHeight();
    setTimeout(updateHeight, 200); // TODO fix later
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    }
  }, [updateHeight])

  return [height]
}

const ProductTemplate = ({ pageContext }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [height] = useHeight(ref);

  const { product, seeAlso } = pageContext;

  const isAvailable = useProductAvailable(product.shopifyId);
  const [selectedVariant, setSelectedVariant] = useState(undefined);
  const isLoading = useIsAdding();

  useClientEffect(() => {
    dispatch(checkAvailability(product.shopifyId));
  });

  const images = product.images.map(img => img.originalSrc);

  const onSubmit = () => {
    dispatch(addToCheckout({ variant: selectedVariant, quantity: 1 }));
  }

  // const price = Math.max(...product.variants.map(({ price }) => Number(price)));
  // const compareAtPrice = Math.max(...product.variants.map(({ compareAtPrice }) => Number(compareAtPrice)));

  const lookCompleted = seeAlso.length !== 0;

  const { price, compareAtPrice } = extractPrice(product);

  return (
    <Layout>
      <Meta title={product.title} />

      <ColoredSeparator />
      <Container>
        <Carousel images={images} name={product.title} tags={product.tags} />
        <Informations>
          <Title>{product.title}</Title>
          <Price price={price} compareAtPrice={compareAtPrice} />
          <Separator size={24} />
          <Title size={2}>{"Tailles"}</Title>
          <Separator size={16} />
          <SizesContainer>
            {product.variants.map(variant => {
              const id = variant.id.split("Shopify__ProductVariant__")[1];
              return (
                <SizeStyled key={id} onClick={() => setSelectedVariant(id)} isAvailable={isAvailable[id]} isSelected={selectedVariant === id}>
                  {variant.title}
                </SizeStyled>
              )
            })
            }
          </SizesContainer>
          <Separator size={24} />
          <Title size={2}>{"Description"}</Title>
          <Separator size={8} />
          <Description>{product.descriptionHtml}</Description>
          <Separator size={24} />
          <Button onClick={onSubmit} isLoading={isLoading} disabled={!selectedVariant}>{selectedVariant ? "Acheter" : "Sélectionner une taille"}</Button>
        </Informations>
        <Crotchet />
        <Separator size={64} />
        {lookCompleted && <LookContainer>
          <TitleEffect>Complétez le look</TitleEffect>
          <Looks height={height}>
            <OutsideContainer ref={ref}>
              {seeAlso.map(({ node: product }) => {
                const to = `/products/${product.handle}`;
                const imgSrc = product.images[0].originalSrc;
                const title = product.title;
                const price = `${Math.max(...product.variants.map(({ price }) => Number(price)))}€`;
                return (
                  <Look to={to} key={product.id}>
                    <LookImageContainer>
                      <LookImage src={imgSrc} />
                      <Macaron width="48" tags={product.tags} />
                    </LookImageContainer>
                    <LookTitle>{title}</LookTitle>
                    <LookPrice>{price}</LookPrice>
                  </Look>
                )
              })}
            </OutsideContainer>
          </Looks>

        </LookContainer>
        }
      </Container>
    </Layout>
  );
}
export default ProductTemplate