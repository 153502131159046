import React from 'react';
import styled, {keyframes} from 'styled-components';

const Container = styled.div`
  position: relative;
  z-index: 2;
  padding: 8px 8px 8px 8px;
  background-color: ${props => props.disabled ? "#6B6B6B" : "black"};
  font-family: 'GLECB', 'GLECB2';
  color: white;
  text-align: center;
  font-size: 2rem;
  box-sizing: border-box;
  cursor: ${props => props.disabled ? "inherit" : "pointer"};
  user-select: none;
`;

const Notes = styled.div`
  display: flex;
  justify-content:center;
`;

const flicker = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Note = styled.div`
  top: 0;
  opacity: 0;
  margin-right: 4px;
  margin-left: 4px;
  animation: ${flicker};
  animation-duration: 0.4s;
  animation-delay: ${props => props.delay}s;
  animation-fill-mode: forwards;
`;


const Button = ({onClick, children, isLoading, disabled}) => {
  const notes = [0, 0.1, 0.2, 0.3, 0.4];
  const checkedOnClick = disabled || isLoading ? () => {} : onClick;
  return (
    <Container onClick={checkedOnClick} disabled={disabled}>
      {!isLoading 
        ? children
        : (
          <Notes>
            {notes.map(delay => <Note delay={delay}>&#9834;</Note>)}
          </Notes>
        )
      }
    </Container>
  );
}

export default Button;
