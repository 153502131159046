import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-size: 1rem;
    margin-top: 24px;
    margin-bottom: 4px;
  }
  p {
    margin-top: 4px;
    margin-bottom: 0px;
  }
`;


const Description = ({ children }) => {
  const regex = /<p>\s*<\/p>/ig;
  const html = (children || '').replace(regex, '');


  return <Container dangerouslySetInnerHTML={{ __html: html }} />;
}

export default Description;