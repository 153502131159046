import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'GLECB','GLECB2';
  font-size: 2rem;
  color: #212121;
`;

const CompareAtPrice = styled.div`
  position: relative;
  display: inline-block;
  color: #5F5F5F;
  margin-left: 24px;
  :before {
    content: '';
    border-bottom: 2px solid #5F5F5F;
    width: 120%;
    position: absolute;
    left: -10%;
    top: 50%;
  }
`

const Price = ({ price, compareAtPrice }) => {
  const priceStr = price + "€";
  const compareAtPriceStr = compareAtPrice !== 0 && compareAtPrice !== price ? " " + compareAtPrice + "€" : undefined;
  return (
    <Container>
      {priceStr}
      {compareAtPrice ? <CompareAtPrice>{compareAtPriceStr}</CompareAtPrice> : null}
    </Container>
  );
}
export default Price;