import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useClientEffect = effect => {
  const client = useSelector(state => state.client);
  
  useEffect(() => {
    if(client){
      effect()
    }
  }, [client])
}

