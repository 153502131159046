import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 8px 8px 8px 8px;
  border: 1px solid ${props => props.isSelected ? 'black' : '#e1e1e1'};
  border-radius: 12px;
  width: 42px;
  height: 42px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.isAvailable ? 'pointer' : 'inherit'};
  user-select: none;
  ${props => props.isAvailable ? `
    &:hover {
      border-color: black;
      color: black;
    }
  `: ''}

  color: black;

  ${props => !props.isAvailable ?
    `&:after {
      content:"";
      position:absolute;
      border-top: 1px solid #f1ece9;
      width: 47px;
      transform: rotate(45deg);
      transform-origin: 50%;
    }`
  : ''}
`;

const Size = ({className, children, onClick, isSelected, isAvailable}) => {
  const realOnClick = isAvailable ? onClick : () => {}
  return (
    <Container className={className} onClick={realOnClick} isSelected={isSelected} isAvailable={isAvailable}>
      {children}
    </Container>
  );
}

export default Size;