import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';


const LinkTo = styled(Link)`
  text-decoration: none;
  color: #5F5F5F;
  &:hover{
    font-weight: 600;
    color: black;
  }
`;

const DotSeparator = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;

const Name = styled.span`
  color: black;
  font-weight: 600;
`;

const Container = styled.div`
  @media(max-width: 768px){
    padding-left: 4px; 
  }
`;

const Path = ({ name }) => {
  return <Container>
    <LinkTo to="/">Accueil</LinkTo>
    <DotSeparator>.</DotSeparator>
    <LinkTo to="/products">Boutique</LinkTo>
    <DotSeparator>.</DotSeparator>
    <Name>{name}</Name>
  </Container>
}

export default Path;